import React, { memo, useContext, useMemo, useState } from "react";
import {
  DataGridPro,
  getGridNumericOperators,
  getGridStringOperators,
} from "@mui/x-data-grid-pro";
import CustomFilter from "components/Reports/CustomFilter";
import { UserContext } from "context/UserContext";
import { barChartText, performanceReportText } from "helpers/Localization";
import { FilterContext } from "context/FilterContext";
import {
  convertGridColumnMenu,
  formatDate,
  uuidv4,
} from "helpers/ReusableFunctions";
import { Link, useNavigate } from "react-router-dom";

const renderSocialLinksActions = (params) => {
  return (
    <Link to={params.value} target="_blank" rel="noreferrer">
      {params.value}
    </Link>
  );
};

const TargetedCandidatesTab = ({
  apiRef = null,
  loading = false,
  basicActive = "",
  currentData = {},
  targetCandidates = [],
  pagination = {},
  onPaginationModelChange = {},
}) => {
  const navigate = useNavigate();
  const { language } = useContext(UserContext);
  const { filterStore, setCandidateAccess } = useContext(FilterContext);

  const sortableColumn = true;
  const pinnableColumn = true;

  const filterOperators = getGridStringOperators().filter(
    ({ value }) => !["isEmpty", "isNotEmpty", "isAnyOf"].includes(value)
  );

  const japaneseName = useMemo(
    () => [
      {
        field: "kanji_last",
        headerName: performanceReportText.lastNameJ[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "kanji_first",
        headerName: performanceReportText.firstNameJ[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  const englishName = useMemo(
    () => [
      {
        field: "first_name",
        headerName: performanceReportText.firstName[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "last_name",
        headerName: performanceReportText.lastName[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [language]
  );

  const columns = useMemo(
    () => [
      {
        field: "date_added",
        headerName: "Date added",
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        renderCell: ({ value }) => formatDate(value),
      },
      ...(!["no", "maybe"].includes(filterStore.PitchResponse)
        ? language === "ja"
          ? japaneseName
          : englishName
        : []),
      {
        field: "current_employer",
        headerName: performanceReportText.employer[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "current_title",
        headerName: performanceReportText.title[language],
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      ...(["no"].includes(filterStore.PitchResponse)
        ? [
            {
              field: "response_reason",
              headerName: barChartText.reason[language],
              width: 200,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
            },
          ]
        : []),
      {
        field: "salary",
        headerName: performanceReportText.salary[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
      },
      {
        field: "salary_breakdown",
        headerName: performanceReportText.salaryBreakdown[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "english",
        headerName: performanceReportText.english[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
      },
      {
        field: "japanese",
        headerName: performanceReportText.japanese[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
        type: "number",
        align: "left",
        headerAlign: "left",
      },
      ...(!["no", "maybe"].includes(filterStore.PitchResponse)
        ? [
            ...(language === "en" ? japaneseName : englishName),
            {
              field: "linkedin_url",
              headerName: performanceReportText.linkedinUrl[language],
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
            {
              field: "twitter_url",
              headerName: performanceReportText.twitterUrl[language],
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
            {
              field: "facebook_url",
              headerName: performanceReportText.facebookUrl[language],
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
            {
              field: "github_url",
              headerName: performanceReportText.githubUrl[language],
              width: 150,
              sortable: sortableColumn,
              pinnable: pinnableColumn,
              filterOperators: filterOperators,
              renderCell: renderSocialLinksActions,
            },
          ]
        : []),
      {
        field: "age",
        headerName: performanceReportText.age[language],
        width: 150,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "education_history",
        headerName: performanceReportText.educationHistory[language],
        width: 200,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
      {
        field: "work_history",
        headerName: performanceReportText.workHistory[language],
        width: 500,
        sortable: sortableColumn,
        pinnable: pinnableColumn,
        filterOperators: filterOperators,
      },
    ],

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [englishName, filterStore.PitchResponse, japaneseName]
  );

  const [columnsOrder, setColumnsOrder] = useState(columns);

  const columnsWithOperators = columnsOrder.map((col) => {
    if (col.type === "number") {
      return {
        ...col,
        filterOperators: [
          ...getGridNumericOperators()
            .filter((operator) => {
              if (operator.value === "=") operator.label = "equals";
              if (operator.value === ">") operator.label = "greater than";
              if (operator.value === "<") operator.label = "less than";
              return (
                operator.value === ">" ||
                operator.value === "<" ||
                operator.value === "="
              );
            })
            .map((operator) => ({
              ...operator,
              InputComponent: CustomFilter,
            })),
        ],
      };
    }

    const customFilterOperators = getGridStringOperators().map((operator) => ({
      ...operator,
      InputComponent: CustomFilter,
    }));

    return {
      ...col,
      filterOperators: customFilterOperators,
    };
  });

  const handleSelectRow = (data) => {
    if (!data?.candidate_id) return;
    setCandidateAccess({
      job: currentData,
      openedTab: basicActive,
    });
    navigate(`/candidates/${data?.candidate_id}/card`);
  };

  return (
    <div className="container job-details-card">
      <div className="cstm-mui-datagrid">
        <DataGridPro
          loading={loading}
          rows={targetCandidates}
          rowCount={pagination?.count}
          pagination
          pageSizeOptions={[50, 100, 500, 1000]}
          onPaginationModelChange={onPaginationModelChange}
          columns={columnsWithOperators}
          slotProps={{
            filterPanel: {
              sx: { maxWidth: "calc(90vw - 24px)" },
            },
            pagination: {
              backIconButtonProps: {
                disabled: loading || !pagination?.offset,
              },
              nextIconButtonProps: {
                disabled: loading || !pagination?.hasMore,
              },
            },
          }}
          getRowId={(row) => row?.candidate_id || uuidv4()}
          onColumnOrderChange={(c) => {
            setColumnsOrder((prevCols) => {
              const newCols = [...prevCols];
              newCols.splice(c.oldIndex, 1);
              newCols.splice(c.targetIndex, 0, c.column);
              return newCols;
            });
          }}
          initialState={{
            ...columns.initialState,
            columns: columns.initialState?.columns,
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          onRowClick={(params) => handleSelectRow(params?.row)}
          localeText={convertGridColumnMenu(language)}
          apiRef={apiRef}
          rowsLoadingMode={"server"}
        />
      </div>
    </div>
  );
};

export default memo(TargetedCandidatesTab);
